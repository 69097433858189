<template>
  <div v-show="!isHidden" :class="CSSClasses" :style="computedStyle">
    <el-form-item
      :prop="name"
      :label="label"
      :class="elFormCssClasses"
      :style="elFormCss"
    >
      <span slot="label" :class="labelCssClasses" :style="labelCss">
        {{ label }}
        <!-- <el-tooltip v-if="tooltip" class="item" effect="dark" :content="tooltip">
          <i class="el-icon-question"></i>
        </el-tooltip> -->
      </span>

      <el-select
        v-model="localModel"
        :name="name"
        :style="styleSelect"
        :size="size"
        :placeholder="placeholder || $locale.main.placeholder.select"
        :disabled="isDisabled"
        :clearable="clearable"
        filterable
        collapse-tags
        :multiple="multiple"
        @clear="localModel = (multiple ? [] : null);$emit('input', localModel)"
        @change="$emit('input', localModel)"
      >
        <el-option
          v-for="(item, index) in options"
          :key="index"
          :label="normalizedLabel(item)"
          :title="item.name"
          :value="item.id">
        </el-option>
      </el-select>
    </el-form-item>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import VisibleMixin from '../visible_properties_mixin'
import DisableMixin from '../disable_properties_mixin'
import InputLabel from '@/mixins/inputLabel.js'
import FilterBuilder, { EComponentTypes } from '../utils'

export default {
  name: 'a-select',
  inject: {
    forceUpdateSettingsPanel: {
      default: () => () => {}
    }
  },
  mixins: [mixin, DisableMixin, VisibleMixin, InputLabel],
  props: {
    value: {
      frozen: true
    },
    model: {
      frozen: true
    },
    readonly: {
      frozen: true
    },
    initialOptions: {
      type: Array,
      description: 'Набор значений',
      default: () => [],
      frozen: true
    },
    disabled: {
      type: Boolean,
      description: 'Заблокированное',
      frozen: true
    },
    editorAlias: {
      type: String,
      description: 'Псевдоним'
    },
    label: {
      type: String,
      description: 'Название'
    },
    name: {
      type: String,
      description: 'Атрибут',
      options: {
        removeSpaces: true
      }
    },
    defaultValue: {
      type: String,
      description: 'Значение по умолчанию'
    },
    registryId: {
      type: Number,
      description: 'Выберите реестр',
      editor: 'RegistrySelect'
    },
    attributeId: {
      type: Number,
      description: 'Укажите атрибут (id)'
    },
    size: {
      type: String,
      description: 'Размер',
      editor: 'Size'
    },
    margin: {
      type: String,
      description: 'Отступы',
      default: '5px 10px'
    },
    width: {
      type: String,
      description: 'Ширина'
    },
    block: {
      type: Boolean,
      description: 'Во всю строку'
    },
    wrapper: {
      type: Boolean,
      description: 'Блок'
    },
    placeholder: {
      description: 'Плейсхолдер',
      type: String
    },
    filters: {
      type: Array,
      editor: 'Filters',
      options: {
        showXrefOption: true,
        showEqualsTypes: true
      }
    },
    stateId: {
      description: 'Состояние (id)',
      type: String
    },
    multiple: {
      type: Boolean,
      description: 'Множественный выбор'
    },
    clearable: {
      type: Boolean,
      description: 'Очищаемое'
    }
  },
  data () {
    return {
      localModel: null, // this.model || (parseInt(this.value) || null),
      options: this.initialOptions
    }
  },
  computed: {
    getRawModel () {
      if (this.options.find(item => item.id === this.localModel)) {
        return [{ 'id': this.localModel, 'name': this.options.find(item => item.id === this.localModel).name }]
      } else {
        return null
      }
    },
    computedStyle () {
      let css = this.CSS

      if (this.margin) {
        css += ';margin:' + this.margin
      }
      if (this.width && !this.block) {
        css += ';width:' + this.width
      }
      if (!this.block) {
        css += `;display: inline-block; width:${this.width || '200px'}`
      }
      if (this.wrapper) {
        css += ';display: block;'
      }

      return css
    },
    styleSelect () {
      let css = ''
      if (!this.block) {
        css += ';display: inline-block'
      }
      if (this.width) {
        css = ';display: block'
      }
      return css
    },
    dataFilters () {
      const builder = new FilterBuilder(
        this.filters,
        this.getModel(),
        this.$store,
        EComponentTypes.select
      )

      return builder.buildAsRegistryService()
      // let filters = []
      // if (this.filters) {
      //   this.filters.forEach((item) => {
      //     let type = `eq`
      //     if (item.isXref) {
      //       type = `eqx`
      //     }
      //     if (!item.type || item.type === 'field') {
      //       if (this.getModel()[item.attribute] && item.alias) {
      //         filters.push(`${item.alias},${type},${this.getModel()[item.attribute]}`)
      //       }
      //     } else if (item.type === 'constant' && item.alias) {
      //       filters.push(`${item.alias},${type},${item.attribute}`)
      //     }
      //   })
      // }
      // return filters
    }
  },
  watch: {
    dataFilters () {
      this.loadData()
    },
    value (val) {
      if (val !== this.localModel) {
        if (Array.isArray(val)) {
          if (val.length > 0) {
            if (this.multiple) {
              this.localModel = val
            } else {
              this.localModel = parseInt(val[0])
            }
            this.$emit('input', this.localModel)
          }
        } else {
          if (this.multiple) {
            this.localModel = [parseInt(val)]
          } else {
            this.localModel = parseInt(val) || null
          }
          this.$emit('input', this.localModel)
        }
        if (!this.options.find((item) => item.id === this.localModel)) {
          this.loadData()
        }
      }
    },
    localModel: {
      async handler () {
        if (this.getRawData() !== undefined) {
          this.$set(this.getRawData(), this.name, this.getRawModel)
        }
      }
    },
    forceUpdateSettingsPanel: {
      default: () => () => {}
    }
  },
  async mounted () {
    if (this.model) {
      this.localModel = this.model
      this.$emit('input', this.localModel)
    } else if (this.value) {
      let parsed = this.value
      try {
        parsed = JSON.parse(parsed)
      } catch (e) {}
      if (Array.isArray(parsed)) {
        if (this.multiple) {
          this.localModel = parsed.map((item) => item.id ? item.id : item)
        } else {
          this.localModel = parsed.map((item) => item.id ? item.id : item)[0]
        }
      } else {
        if (this.multiple) {
          this.localModel = [parseInt(parsed)]
        } else {
          // проверка, что значение возможно дата
          // parseInt('2021-11-18') -> съедает значение (2021)
          if (typeof parsed === 'string' && RegExp(/\d{4}-\d{2}-\d{2}$/).test(parsed)) {
            this.localModel = parsed
          } else {
            this.localModel = parseInt(parsed)
          }
        }
      }
      this.$emit('input', this.localModel)
    } else if (this.defaultValue) {
      let value = this.defaultValue
      // несколько значений по умолчанию
      if (this.defaultValue.includes(',')) {
        value = this.defaultValue.split(',')
      }
      if (value === '{{user_id}}') {
        value = this.$store.getters['Authorization/userId']
      }
      if (value === '{{role_id}}') {
        value = this.$store.getters['Authorization/roleId']
      }
      if (/user.attr_[0-9]+_/i.test(value)) {
        value = await this.$store.getters['Authorization/userAttributeData'](value.match(/attr_[0-9]+_/gi)[0])
      }
      try {
        value = JSON.parse(value)
      } catch (e) {
      }
      if (typeof value !== 'number' && Array.isArray(value)) {
        let temp = []
        if (value.length > 0) {
          value.forEach((item) => {
            temp.push(parseInt(item.id || item))
          })
        }
        if (temp.length > 0) {
          this.localModel = this.multiple ? temp : parseInt(temp[0])
        }
      } else {
        this.localModel = (this.multiple ? [parseInt(value)] : parseInt(value))
      }
      this.$emit('input', this.localModel)
    }
    // ждем фильтры dataFilters... bad bug
    setTimeout(() => {
      this.loadData()
    }, 500)
  },
  methods: {
    normalizedLabel (label) {
      let result = label.name?.length > 50 ? label.name.substring(0, 50) + '...' : label.name

      return result
    },
    async loadData () {
      if (this.registryId && this.attributeId) {
        let params = []
        this.dataFilters.forEach((filter, index) => {
          params.push(`filter[${index}]=${filter}`)
        })
        if (this.stateId) {
          params.push(`state_id=${this.stateId}`)
        }
        let answer = await this.$http.get(`${this.$config.api}/registryservice/xref/?registry_id=${this.registryId}&attribute_id=${this.attributeId}&${params.join('&')}`)
        this.options = answer.data
      }
    }
  }
}
</script>

<style scoped>
</style>
